#container:hover div {
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -ms-filter: blur(1px);
  -o-filter: blur(1px);
  filter: blur(1px);
  transform: scale(1.03);
}

#container #icon {
  position: absolute;
  display: "flex";
  justify-content: "center";
  align-items: "center";
  opacity: 0;
  transition: "opacity 0.3s";
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  filter: blur(0);
}

#container:hover #icon {
  -webkit-opacity: 1;
  opacity: 1;
}
