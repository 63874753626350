/* styles.css */
.compossTable {
  border-collapse: separate;
  border: 1px solid #ececec;
  padding: -50px;
  font-family: "Akatab", sans-serif;
  font-size: 12px;
  /* margin: 10px; */
}

.compossTable th,
.compossTable td {
  padding: 10px;
}

.compossTableCell {
  background-color: #fafafa;
  color: #9499a4;
  font-family: "Akatab", sans-serif;
  font-size: 12px;
  font-weight: 400;
  height: 10px;
}

.tableEachCell {
  height: 18px;
}

.compossHeaderText {
  font-size: 13px;
}

.tableRow > * {
  border: none;
}

.tableRow {
  height: 8px;
}

.customIcon {
  font-size: 16px;
}
