/* styles.css */
.leaveShowTable {
    border-collapse: separate;
    border: 1px solid #ececec;
    padding: 0;
    }
    
    .leaveShowTable th,
    .leaveShowTable td {
      padding: 4px;
    }
    
    .leaveShowTableCell {
      background-color: #fafafa;
      color: #9499a4;
      font-family: 'Akatab', sans-serif;
      font-size: -16px;
      font-weight: 500;
      height: 20px;
    }
    
    .leaveShowTableEachCell {
      height: 18px;
    }
    
    .leaveShowHeaderText {
      font-size: 13px;
    }
    
    .leaveShowTableRow > * {
      border: none;
    }
    
    .tableRow {
      height: 8px;
    }
    
    .leaveShowCustomIcon {
      font-size: 16px;
    }
    