
body {
  zoom: 100%;
}

.main {
  margin: 0;
  padding: 0;
  /* background-color: #fafafa; */
  width: auto;
  height: auto;
 
}
.main::-webkit-scrollbar {
  display: none;
}

.video-react.video-react-fluid, .video-react.video-react-16-9, .video-react.video-react-4-3 {
  padding-top: 0% !important;
  height:100% !important;
  max-height: 500px !important;
}
.video-react .video-react-big-play-button{
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.recharts-text.recharts-label tspan{
  font-size: 8px;
  max-width: 100px;
}


.swal2-container.swal2-center.swal2-backdrop-show{
  z-index: 1310 !important;
}
