/* Custom horizontal scrollbar for the table */
.application-table-scroll {
    overflow-x: auto;
  }
  
  /* Webkit Browsers */
  .application-table-scroll::-webkit-scrollbar {
    height: 8px;
  }
  
  .application-table-scroll::-webkit-scrollbar-track {
    background: #efedf0; /* Track color */
    border-radius: 10px;
  }
  
  .application-table-scroll::-webkit-scrollbar-thumb {
    background-color: #888; /* Scroll thumb color */
    border-radius: 10px;
    border: 2px solid #efedf0; /* Optional border around the thumb */
  }
  
  .application-table-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Thumb color on hover */
  }
  
  /* Firefox */
  .application-table-scroll {
    scrollbar-width: thin; /* Make scrollbar thinner */
    scrollbar-color: #888 #efedf0; /* Thumb and track color */
  }
  