/* styles.css */
.incentiveTable {
    border-collapse: separate;
    border: 1px solid #ececec;
    /* padding: 1px; */
    }
    
    .incentiveTable th,
    .incentiveTable td {
      padding: 4px;
    }
    
    .incentiveTableCell {
      background-color: #fafafa;
      color: #9499a4;
      font-family: 'Akatab', sans-serif;
      font-size: -16px;
      font-weight: 500;
      height: 20px;
    }
    
    .incentiveTableEachCell {
      height: 18px;
    }
    
    .incentiveHeaderText {
      font-size: 13px;
    }
    
    .incentiveTableRow > * {
      border: none;
    }
    
    .tableRow {
      height: 8px;
    }
    
    .incentiveCustomIcon {
      font-size: 16px;
    }
    