/* styles.css */
.commonTable {
  border-collapse: separate;
  border: 1px solid #ececec;
  padding: 15px;
  }
  
  .commonTable th,
  .commonTable td {
    padding: 14px;
  }
  
  .commonTableCell {
    background-color: #fafafa;
    color: #9499a4;
    font-family: 'Akatab', sans-serif;
    font-size: -16px;
    font-weight: 500;
    height: 20px;
  }
  
  .tableEachCell {
    height: 18px;
  }
  
  .commonHeaderText {
    font-size: 13px;
  }
  
  .tableRow > * {
    border: none;
  }
  
  .tableRow {
    height: 8px;
  }
  
  .customIcon {
    font-size: 16px;
  }
  